import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Head from '../components/head'

const AboutPage = () => {
    return (
        <Layout>
            <Head title="About"/>
            <h1>About our Institute for New Economic Thinking grant team</h1>
            <p>Frontiers of Finance in China was started out of a shared research project on financial innovation and central banking in China, started by Luke Deer, Mike Beggs, Chris Jefferis and Yu Yuxin. Christopher Aston has also contributed to research on this project.</p>
            <p>The first phase of our research project has been funded by a 2015 Grant from <a href="https://www.ineteconomics.org/research/grants/financial-innovation-and-central-banking-in-china-a-money-view" target="_blank" rel="noopener noreferrer">The Institute for New Economic Thinking (INET)</a>.</p>
            <h2><Link to="/luke-deer">Luke Deer</Link></h2>
            <h2><a href="http://sydney.edu.au/arts/political_economy/staff/profiles/michael.beggs.php" target="_blank" rel="noopener noreferrer">Michael Beggs</a></h2>
            <p>Dr. Mike Beggs is a Senior Lecturer in Political Economy at The University of Sydney. His book,&nbsp;<em><a href="https://www.amazon.com/Inflation-Making-Australian-Macroeconomic-1945-85-ebook/dp/B015YB0FNQ/ref=mt_kindle?_encoding=UTF8&amp;me=" target="_blank" rel="noopener noreferrer">Inflation and the Making of Macroeconomic Policy in Australia</a>&nbsp;</em>was<em>&nbsp;</em>published by Palgrave in 2015. His research focuses on the the historical co-evolution of financial innovation, monetary policy and macroeconomic theory. He has written papers on the history of Australian monetary policy, most recently one in <em>Australian Economic History Review</em> on central banking and financial innovation in the 1950s. He teaches advanced undergraduate and postgraduate courses on the political economy of money and finance.</p>
            <h2><a href="https://www.jfki.fu-berlin.de/en/faculty/politicalscience/team/former/jefferis/index.html" target="_blank" rel="noopener noreferrer">Chris Jefferis</a></h2>
            <p>Dr. Chris Jefferis is a Postdoctoral Fellow at the Free University of Berlin. His book,&nbsp;<a href="https://www.amazon.com/Dialectics-Liquidity-Crisis-interpretation-International-ebook/dp/B06WGSFCFW/ref=sr_1_fkmr0_1?ie=UTF8&amp;qid=1500523846&amp;sr=8-1-fkmr0&amp;keywords=The+Dialectics+of+Liquidity+Crisis%3A+an+interpretation+of+the+financial+crisis+of+2007-08" target="_blank" rel="noopener noreferrer">The Dialectics of Liquidity Crisis: an interpretation of explanations of the financial crisis of 2007-08</a>, which is&nbsp;an analysis of the application of Minsky’s financial instability hypothesis to the crisis, was published by Routledge in 2017. In 2006 he worked on a research project with Professor Frank Stilwell on securitisation and financial instability in Australia and was the lead author of a case study looking at the Australian investment bank Macquarie Bank. This article titled “Private finance for public infrastructure: the case of Macquarie Bank” was published in the <em>Journal of Australian Political Economy</em>. He has also published articles about finance in the <em>South Atlantic Quarterly</em> in a joint publication with Professor Dick Bryan and Dr. Mike Rafferty and the <em>Journal of Cultural Economy</em>.</p>
            <h2>Yu Yuxin</h2>
            <p>Yu Yuxin is an Assistant Professor in the School of Economics and Finance at Shanghai International Studies University. His research areas are financial markets, China’s macro-economy, financial innovation, and applications of big data. Yuxin is particularly interested in research on internet finance in China. He teaches courses on macroeconomics, the world economy, industrial economics, enterprise strategy management. He has been a visiting researcher at Fudan University in Shanghai and a Visiting Scholar at Virginia Tech (Virginia Polytechnic Institute and State University) in the United States.</p>
            <h2><a href="https://twitter.com/keqiangCN" target="_blank" rel="noopener noreferrer">Christopher Aston</a></h2>
            <p>Christopher Aston is a freelance writer and researcher on China’s economy. His research focuses on alternative finance in China, and he has experience working in a Chinese ‘shadow’ bank and in an international investment bank. Christopher is also a freelance contributor for the Economist Intelligence Unit, and author of the blog <a href="https://chiecon.wordpress.com/" target="_blank" rel="noopener noreferrer">chiecon</a>, which translates materials on China’s economy. A University of Liverpool MSc. Finance holder, he will contribute to this project mapping out capital market development and ‘shadow’ banking in China.</p>
            <p><Link to="/contact">Get in touch if you are interested in working together, exchanging or talking more.</Link></p>
        </Layout>
    )
}

export default AboutPage